import {Column} from '../../../../components/AppDataTable'

export interface IPendingCompletedRejectedCount {
  pendingCount: number
  completedCount: number
  rejectedCount: number
  approvedCount: number
}

export const initialCountData: IPendingCompletedRejectedCount = {
  pendingCount: 0,
  completedCount: 0,
  rejectedCount: 0,
  approvedCount: 0,
}

export interface IRejectedSurveysReasonList {
  id: string
  pileCode: string
  rejectedReason: string
}
export interface IRejectedSurveyReasonColumns extends Column {
  field: keyof IRejectedSurveysReasonList
}

export const rejectedReasonColumns: IRejectedSurveyReasonColumns[] = [
  {field: 'pileCode', headerText: 'Pile Code', width: '200'},
  {field: 'rejectedReason', headerText: 'Rejected Reason', width: '200'},
]

export type rejectedSurveyStateType = {
  visible: boolean
  list: IRejectedSurveysReasonList[]
}
export const initialRejectedSurveyState: rejectedSurveyStateType = {
  list: [],
  visible: false,
}
