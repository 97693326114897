import {FilterSettingsModel, FilterType, PageSettingsModel} from '@syncfusion/ej2-react-grids'
import {jwtDecode} from 'jwt-decode'
import {IGetSurveyData, RadioButtonOption} from '../modules/MaterialDashboard/model'
import {AppToast} from './AppToast'
import {IVolumetricAnalysisList} from '../modules/MaterialDashboard/PileManagement/Models/volumetricAnalysisModel'
import {
  IPileCountDataFromServer,
  IPileCountList,
} from '../modules/MaterialDashboard/PileManagement/Models/pileApprovalRejectionModel'
import moment from 'moment'

export const dateFormat = 'YYYY-MM-DD'
export const appDefaultFormat = 'MMM D, YYYY'
export const getDateInDefaultFormat = (date: Date, format = dateFormat) =>
  moment(date).format(format)

export const getJwtDataFromToken = (token: string) => {
  const decoded: Record<string, any> = jwtDecode(token)
  return decoded
}
export function getListWithSerialNo<dataType>(data: dataType[]) {
  return data.map((ele, index) => ({...ele, srno: index + 1}))
}

export function getGridOptionsForDataTable(pageSize: number = 10, type: FilterType = 'Menu') {
  const pageSettings: PageSettingsModel = {pageSize, pageSizes: true}
  const filterSettings: FilterSettingsModel = {type}

  const gridOptions = {
    pageSettings,
    filterSettings,
  }
  return gridOptions
}
export function getYesOrNoOptions() {
  const yesNoOptions: RadioButtonOption[] = [
    {label: 'Yes', value: true},
    {label: 'No', value: false},
  ]
  return yesNoOptions
}

export function getUnsuccessfulToastMessage(error: any, defaultMessage: string) {
  const {toast} = AppToast
  if (error?.response && error?.response?.data && error?.response?.data?.message) {
    const errorMessage = error?.response?.data?.message
    toast.error(errorMessage)
  } else {
    console.log('in default part of toast')
    toast.error(defaultMessage)
  }
}

export const checkIsSameDate = (firstDate: Date, secondDate: Date) => {
  return (
    firstDate.getDate() === secondDate.getDate() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getFullYear() === secondDate.getFullYear()
  )
}

export const formatPileCountList = (pileCountData: IPileCountDataFromServer[]) => {
  const formattedList: IPileCountList[] = pileCountData?.map((pileCount) => {
    const {completed, id, name, pending, total} = pileCount
    return {completed, id, name, pending, total}
  })
  return formattedList
}

export const formatVolumetricAnalysisList = (data: IGetSurveyData[]) => {
  const volumetricAnalysisList: IVolumetricAnalysisList[] = data.map((survey) => ({
    id: survey.id,
    pileCode: survey.pileCode,
    pileName: survey.name,
    locationName: survey.Department?.location?.name,
    derivedErpQuantity: survey.derivedErpQuantity,
    volume: Number(survey?.volume),
    additionalInformation: survey?.additionalInformation || '',
    toeConfidence: survey?.toeConfidence || 'Low',
    surfaceConfidence: survey?.toeConfidence || 'Low',
    date: new Date(survey.date),
    health: [],
    combinedPiles: survey?.combinedPiles || false,
    obstructionStructure: survey?.obstructionStructure || false,
    pileHealth: survey?.pileHealth || false,
    standingWater: survey?.pileHealth || false,
    tarpaulinOnPile: survey?.tarpaulinOnPile || false,
    vegetationGrowth: survey?.vegetationGrowth || false,
    demSnap: false,
    volumetricComp: false,
    orthoSnap: false,
    picSnap: false,
    pointCloud: false,
    demTiff: false,
    orthoTiff: false,
    pointCloudLas: false,
  }))
  return volumetricAnalysisList
}
