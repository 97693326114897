import {useEffect, useState} from 'react'
import {
  initialCountData,
  initialRejectedSurveyState,
  IPendingCompletedRejectedCount,
  IRejectedSurveysReasonList,
  rejectedReasonColumns,
  rejectedSurveyStateType,
} from './Models/analystModel'
import clsx from 'clsx'
import {useNavigate} from 'react-router-dom'
import {appRoutes} from '../../../routing/routes'
import {surveyTypeObj} from '../../../Utils/constants'
import {getAnalystRejectedSurveys, getAnalystSurveyCount} from './service'
import AppLoaderWithContainer from '../../../components/AppLoaderWithContainer'
import AppDataTable from '../../../components/AppDataTable'
import {getGridOptionsForDataTable} from '../../../Utils/getTokenDecoded'
import AppSubmitButtonContainer from '../../../components/AppSubmitButtonContainer'
import AppButton from '../../../components/AppButton'

const AnalystDashboard = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const [analystCountData, setAnalystCountData] =
    useState<IPendingCompletedRejectedCount>(initialCountData)

  const {completedCount, pendingCount, rejectedCount, approvedCount} = analystCountData

  const [rejectedReasonData, setRejectedReasonData] = useState<rejectedSurveyStateType>(
    initialRejectedSurveyState
  )

  const navigateToRejectedSurveysScreen = () => {
    navigate(`${appRoutes.pileVolumetricAnalysisRoute}/${surveyTypeObj.rejected}`)
  }

  const changeAnalystCountData = (fields: Partial<IPendingCompletedRejectedCount>) => {
    setAnalystCountData((prev) => ({...prev, ...fields}))
  }

  const handlRejectionClick = () => {
    navigateToRejectedSurveysScreen()
  }

  const handleRejectedCountClick = (rejectedCount: number) => {
    if (rejectedCount === 0) return
    setRejectedReasonData((prev) => ({...prev, visible: !prev.visible}))
  }

  const fetchRejectedSurveys = async () => {
    try {
      const {data} = await getAnalystRejectedSurveys()
      setRejectedReasonData((prev) => ({
        ...prev,
        list: data.map(({id, pileCode, rejectedReason}) => ({id, rejectedReason, pileCode})),
      }))
    } catch (error) {
      console.error('fetch rejected surveys error', error)
    }
  }

  useEffect(() => {
    const fetchAnalystSurveyCount = async () => {
      try {
        setLoading(true)
        const {data} = await getAnalystSurveyCount()
        changeAnalystCountData({...data})
        if (data.rejectedCount > 0) fetchRejectedSurveys()
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }
    fetchAnalystSurveyCount()
  }, [])

  return (
    <div className='container'>
      {loading ? (
        <AppLoaderWithContainer loading={loading} />
      ) : (
        <div>
          <div className='row text-center mb-5'>
            <p className='h3 text-center mb-5'>Analyst Survey Count</p>
            <div className='col-12 col-md-6 col-lg-3 mb-3'>
              <div className='p-3 border bg-light rounded'>
                <h3 className='text-success'>Approved</h3>
                <p className='h5 text-success'>{approvedCount}</p>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-3 mb-3'>
              <div className='p-3 border bg-light rounded'>
                <h3 className='text-success'>Completed</h3>
                <p className='h5 text-success'>{completedCount}</p>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-3 mb-3'>
              <div className='p-3 border bg-light rounded'>
                <h3 className='text-warning'>Pending</h3>
                <p className='h4 text-warning'>{pendingCount}</p>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-3 mb-3'>
              <div
                className={clsx('p-3 border bg-light rounded', {
                  'cursor-pointer': rejectedCount > 0,
                })}
                onClick={() => handleRejectedCountClick(rejectedCount)}
              >
                <h3 className={clsx('text-danger')}>Rejected</h3>
                <p className={clsx('h4 text-danger')}>{rejectedCount}</p>
              </div>
            </div>
          </div>
          {rejectedReasonData.visible ? (
            <div className='p-4'>
              <AppDataTable
                data={rejectedReasonData.list}
                columns={rejectedReasonColumns}
                gridOptions={getGridOptionsForDataTable()}
              />
              <AppSubmitButtonContainer className='mt-4'>
                <AppButton onClick={handlRejectionClick} label={'Go to Rejected Surveys'} />
              </AppSubmitButtonContainer>
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default AnalystDashboard
