export const primaryColor = '#febd16'
export const whiteColor = '#fff'
export const redColor = '#F64E60'
export const blackColor = '#333'

export const fileUplaodStatus = {
  UploadedToS3FromFrontend: 'UploadedToS3FromFrontend',
  DownloadedToServer: 'DownloadedToServer',
  Processing: 'Processing',
  Processed: 'Processed',
  UploadedToS3AfterProcessing: 'UploadedToS3AfterProcessing',
  DeletedFromServerAfterProcessing: 'UploadedToS3AfterProcessing',
}

export type CompanyRoles = 'CompanyAdmin' | 'User' | 'LocationHead' | 'Auditor'
export type DatesmetronRoles = 'Superadmin' | 'DatesmetronAnalyst' | 'Supervisor' | 'Surveyor'
export type companyOrDatesmetronRole = CompanyRoles | DatesmetronRoles

export const fileTypeObj = {
  Ortho: 'Ortho',
  Pointcloud: 'Pointcloud',
  Shape: 'Shape',
  Snap: 'Snap',
  Images: 'Images',
}
export const surveyType = 'surveyType'
export const surveyTypeObj = {
  all: 'all',
  rejected: 'rejected',
}
export type StockyardType = 'Indoor' | 'Outdoor'
