import axios from 'axios'
import {
  IAddBulkMaterialRequest,
  IAddBulkMaterialResponse,
  ICreateMaterialResponse,
  IGetMaterialResponse,
  IGetSurveyBySurveyIdResponse,
  IGetSurveyData,
  ISurveyDataAndCount,
  ISurveyPhotoUpload,
  IVolumetricDataRequest,
  IVolumetricDataResponse,
  updateSurveyRequestObj,
} from './model'
import {createSurveyRequestObj} from './AddSurvey/model'

const BASE_URL = process.env.REACT_APP_SERVER_URL
const CREATE_BULK_MATERIAL_URL = `${BASE_URL}/bulk-material/create-bulk-material`
const UPDATE_BULK_MATERIAL_URL = `${BASE_URL}/bulk-material/update-bulk-material`
const DELETE_BULK_MATERIAL_URL = `${BASE_URL}/bulk-material/delete-bulk-material`
const GET_BULK_MATERIAL_URL = `${BASE_URL}/bulk-material`

const GET_SURVEY_URL = `${BASE_URL}/surveys/get-all-surveys`
const DELETE_SURVEY_URL = `${BASE_URL}/surveys/delete-survey`
const UPDATE_SURVEY_URL = `${BASE_URL}/surveys/update-surveys`
const VOLUMETRIC_ANALYSIS_URL = `${BASE_URL}/surveys/update-analysis`
const GET_SURVEY_BY_SURVEY_ID_URL = `${BASE_URL}/surveys`
const APPROVE_SURVEY_URL = `${BASE_URL}/surveys/supervisor/approve`
const REJECT_SURVEY_URL = `${BASE_URL}/surveys/supervisor/reject`
const SURVEY_DATES_COUNT_URL = `${BASE_URL}/surveys/get-survey-count`
const FILE_UPLOAD_URL = `${BASE_URL}/file-uploads/create-file-record`
const GET_SURVEY_BY_STOCKYARD_URL = `${BASE_URL}/surveys/get-surveys-by-stockyard`
const SURVEY_DATES_COUNT_BY_STOCKYARD_URL = `${BASE_URL}/surveys/get-count-by-stockyard`
const GET_ALL_BULK_MATERIALS_URL = `${BASE_URL}/bulk-material/all-materials`

export const createBulkMaterial = (bulkMaterialRequest: IAddBulkMaterialRequest) => {
  return axios.post<ICreateMaterialResponse>(CREATE_BULK_MATERIAL_URL, bulkMaterialRequest)
}
export const updateBulkMaterialById = (
  bulkMaterialId: string,
  bulkMaterialRequest: {name: string; code: string}
) => {
  return axios.patch<IAddBulkMaterialResponse>(
    `${UPDATE_BULK_MATERIAL_URL}/${bulkMaterialId}`,
    bulkMaterialRequest
  )
}
export const deleteBulkMaterialById = (bulkMaterialId: string) => {
  return axios.delete(`${DELETE_BULK_MATERIAL_URL}/${bulkMaterialId}`)
}

export const getBulkMaterialListByDepartmentId = (departmentId: string) => {
  return axios.get<IGetMaterialResponse[]>(`${GET_BULK_MATERIAL_URL}/${departmentId}`)
}
export const getSurveysByDepartmentId = (departmentId: string) => {
  return axios.get<IGetSurveyData[]>(`${GET_SURVEY_URL}/${departmentId}`)
}
export const deleteSurveyById = (surveyId: string) => {
  return axios.delete(`${DELETE_SURVEY_URL}/${surveyId}`)
}
export const updateSurveyById = (surveyId: string, surveyDetails: updateSurveyRequestObj) => {
  return axios.patch<IGetSurveyData>(`${UPDATE_SURVEY_URL}/${surveyId}`, surveyDetails)
}
export const getVolumetricAnalysis = (
  surveyId: string,
  analysisDetails: IVolumetricDataRequest
) => {
  return axios.patch<IVolumetricDataResponse>(
    `${VOLUMETRIC_ANALYSIS_URL}/${surveyId}`,
    analysisDetails
  )
}

export const getSurveyBySurveyId = (surveyId: string) => {
  return axios.get<IGetSurveyBySurveyIdResponse>(`${GET_SURVEY_BY_SURVEY_ID_URL}/${surveyId}`)
}
export const approveSurveyBySurveyId = (surveyId: string) => {
  return axios.patch(`${APPROVE_SURVEY_URL}/${surveyId}`)
}

export const rejectSurveyBySurveyId = (surveyId: string) => {
  return axios.patch(`${REJECT_SURVEY_URL}/${surveyId}`)
}
export const getSurveyDateAndCountByDeptId = (departmentId: string) => {
  const url = `${SURVEY_DATES_COUNT_URL}/${departmentId}`
  return axios.get<ISurveyDataAndCount[]>(url)
}

export const uploadSurveyFile = (surveyId: string, fileData: ISurveyPhotoUpload) => {
  const url = `${FILE_UPLOAD_URL}/${surveyId}`
  return axios.post(url, fileData)
}

export const getSurveysByStockyardId = (stockyardId: string) => {
  return axios.get<IGetSurveyData[]>(`${GET_SURVEY_BY_STOCKYARD_URL}/${stockyardId}`)
}

export const getSurveyDateAndCountByStockyardId = (stockyardId: string) => {
  const url = `${SURVEY_DATES_COUNT_BY_STOCKYARD_URL}/${stockyardId}`
  return axios.get<ISurveyDataAndCount[]>(url)
}

export const getAllBulkMaterials = () => {
  const url = GET_ALL_BULK_MATERIALS_URL
  return axios.get<IAddBulkMaterialResponse[]>(url)
}
