import {FC, HTMLAttributes} from 'react'
import Button from 'react-bootstrap/Button'
export interface AppButtonProps extends HTMLAttributes<HTMLButtonElement> {
  onClick?: (event?: any) => void
  label: string
  className?: string
  variant?: string
  loading?: boolean
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset' // Adding type here
  btnSize?: 'sm' | 'lg'
  loadingText?: string
  icon?: string
}
const AppButton: FC<AppButtonProps> = ({
  onClick,
  label,
  className = '',
  variant = 'primary',
  disabled = false,
  loading = false,
  type = 'button',
  loadingText = 'Please wait...',
  btnSize = 'lg',
  icon = '',
  ...remainingProps
}) => {
  return (
    <Button
      className={`${className}`}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      size={btnSize}
      {...remainingProps}
    >
      {icon && (
        <span className='mr-4'>
          <i className={icon}></i>
        </span>
      )}
      {!loading && <span className='indicator-label'>{label}</span>}
      {loading && (
        <span className='indicator-progress' style={{display: 'block'}}>
          {loadingText}
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )}
    </Button>
  )
}

export default AppButton
