import React, {HTMLAttributes, ReactNode} from 'react'
interface AppSubmitButtonContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}
const AppSubmitButtonContainer = ({
  children,
  className = '',
  ...remainingProps
}: AppSubmitButtonContainerProps) => {
  return (
    <div
      className={`d-flex justify-content-center align-items-center p-1 gap-3 ${className}`}
      {...remainingProps}
    >
      {children}
    </div>
  )
}

export default AppSubmitButtonContainer
