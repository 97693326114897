import {useReducer} from 'react'
import {AppToast} from '../Utils/AppToast'

interface IFileUpload {
  file: File | null
  fileExtension: string
  fileType: string
  loading: boolean
}
const initialState: IFileUpload = {
  file: null,
  fileExtension: '',
  fileType: '',
  loading: false,
}
export const useFileUpload = () => {
  const [fileUploadState, setFileUploadState] = useReducer(
    (state: IFileUpload, fields: Partial<IFileUpload>) => ({...state, ...fields}),
    initialState
  )
  const {toast} = AppToast
  const {file, loading, fileExtension, fileType} = fileUploadState
  const isUploadBtnDisabled = loading || !file

  const handleFileChange = (file: File) => {
    const fileExtension = file.name.split('.')[1]
    setFileUploadState({file, fileExtension})
  }

  const canFileBeUploaded = (): boolean => {
    if (!file) return false

    const fileName = file.name.replace(/ /g, '').split('.')
    const newFileName = `${fileName[0]}${Date.now().toString()}.${fileName[fileName.length - 1]}`
    const isNotCorrectTifFileType =
      fileType === 'tif' && fileExtension !== 'tif' && fileExtension !== 'tiff'
    if (fileType === 'Others') return true
    if (isNotCorrectTifFileType) {
      toast.error('Please upload a valid file')
      return false
    } else if (fileType !== 'tif' && fileType !== fileExtension) {
      toast.error('Please upload a valid extension file')
      return false
    }
    return true
  }

  const handleFileTypeChange = (fileType: string) => setFileUploadState({fileType})
  const setLoading = () => setFileUploadState({loading: true})
  const resetLoading = () => setFileUploadState({loading: false})

  const resetState = () => setFileUploadState(initialState)
  return {
    fileUploadState,
    isUploadBtnDisabled,
    handleFileChange,
    canFileBeUploaded,
    resetState,
    handleFileTypeChange,
    setLoading,
    resetLoading,
  }
}
