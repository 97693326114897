import React from 'react'
import AppLoader from './AppLoader'
interface AppLoaderProps {
  loading: boolean
}
const AppLoaderWithContainer = ({loading}: AppLoaderProps) => {
  return (
    <div className='d-flex justify-content-center align-items-center'>
      <AppLoader loading={loading} />
    </div>
  )
}

export default AppLoaderWithContainer
