import axios from 'axios'
import {
  ICompanyLocationsFromServer,
  ICompanyStockyardFromServer,
  IGetSurveyForSupervisorRequest,
  IPilesApprovalSurveyFromServer,
  ISurveyFromStockyard,
  IVolumetricAnalysisUpdateRequest,
} from './Models/apiModel'
import {IAssignSurveysToAnalyst} from './Models/pileAssignmentModel'
import {IPileCountDataFromServer} from './Models/pileApprovalRejectionModel'
import {CompanyAdminPendingCompleteCount} from './Models/supervisorDashboard'
import {IPendingCompletedRejectedCount} from './Models/analystModel'

const BASE_URL = process.env.REACT_APP_SERVER_URL
const APPROVE_SURVEY_URL = `${BASE_URL}/surveys/supervisor/approve`
const REJECT_SURVEY_URL = `${BASE_URL}/surveys/supervisor/reject`
const COMPANY_LOCATIONS_URL = `${BASE_URL}/location-departments/get-company-location`
const COMPANY_STOCKYARD_URL = `${BASE_URL}/stockyard/get-stockyards-by-location`
const SURVEY_FROM_STOCKYARD_URL = `${BASE_URL}/surveys/get-surveys-by-stockyard`
const UNASSIGNED_SURVEYS_FROM_STOCKYARD_URL = `${BASE_URL}/surveys/get-unassigned-survey`
const ASSIGN_SURVEYS_TO_ANALYST = `${BASE_URL}/surveys/assign-to-user`
const ASSIGNED_SURVEYS_FROM_STOCKYARD_URL = `${BASE_URL}/surveys/get-assigned-survey`
const SURVEY_FOR_SURPERVISOR_URL = `${BASE_URL}/surveys/supervisor/get-all-surveys`
const SURVEY_COUNT_URL = `${BASE_URL}/surveys/get-counts-by-stockyard`
const CLIENT_SURVEY_COUNT_URL = `${BASE_URL}/surveys/supervisor/get-survey-count`
const VOLUMETRIC_ANALYSIS_URL = `${BASE_URL}/surveys/update-analysis`
const ANALYST_REJECTED_SURVEY_URL = `${BASE_URL}/surveys/get-reject-surveys`
const SURVEY_BY_SURVEY_ID_URL = `${BASE_URL}/surveys`
const ANALYST_SURVEY_COUNT_URL = `${BASE_URL}/surveys/analyst/get-survey-count`

export const getSurveyBySurveyId = (surveyId: string) => {
  const url = `${SURVEY_BY_SURVEY_ID_URL}/${surveyId}`
  return axios.get<ISurveyFromStockyard>(url)
}

export const approveSurveyBySurveyId = (surveyId: string) => {
  return axios.patch(`${APPROVE_SURVEY_URL}/${surveyId}`)
}

export const rejectSurveyBySurveyId = (surveyId: string, rejectedReason: string) => {
  return axios.patch(`${REJECT_SURVEY_URL}/${surveyId}`, {rejectedReason})
}

export const getCompanyLocationByCompanyAdminId = (userId: string) => {
  const url = `${COMPANY_LOCATIONS_URL}/${userId}`
  return axios.get<ICompanyLocationsFromServer[]>(url)
}

export const getCompanyStockyardsByLocationId = (locationId: string) => {
  const url = `${COMPANY_STOCKYARD_URL}/${locationId}`
  return axios.get<ICompanyStockyardFromServer[]>(url)
}

export const getSurveyFromStockyardId = (stockyardId: string) => {
  const url = `${SURVEY_FROM_STOCKYARD_URL}/${stockyardId}`
  return axios.get<ISurveyFromStockyard[]>(url)
}
export const getUnAssignedSurveysFromStockyardId = (stockyardId: string) => {
  const url = `${UNASSIGNED_SURVEYS_FROM_STOCKYARD_URL}/${stockyardId}`
  return axios.get<ISurveyFromStockyard[]>(url)
}
export const AssignSurveyToAnalyst = (surveyIds: IAssignSurveysToAnalyst) => {
  const url = ASSIGN_SURVEYS_TO_ANALYST
  return axios.patch<ISurveyFromStockyard[]>(url, surveyIds)
}
export const getAssignedSurveysFromStockyardId = (stockyardId: string) => {
  const url = `${ASSIGNED_SURVEYS_FROM_STOCKYARD_URL}/${stockyardId}`
  return axios.get<ISurveyFromStockyard[]>(url)
}
export const getAllSurveysForSupervisor = ({
  startDate,
  endDate,
  ...remainingFields
}: IGetSurveyForSupervisorRequest) => {
  const url = SURVEY_FOR_SURPERVISOR_URL
  return axios.post<IPilesApprovalSurveyFromServer[]>(url, {
    startDate: startDate?.toISOString(),
    endDate: endDate?.toISOString(),
    ...remainingFields,
  })
}
export const getSurveyCount = (startDate: Date, endDate: Date) => {
  const url = SURVEY_COUNT_URL
  return axios.post<IPileCountDataFromServer[]>(url, {
    startDate: startDate?.toISOString(),
    endDate: endDate?.toISOString(),
  })
}

export const getClientSurveyCount = () => {
  const url = CLIENT_SURVEY_COUNT_URL
  return axios.get<CompanyAdminPendingCompleteCount[]>(url)
}

export const updateVolumetricAnalysisById = (
  surveyId: string,
  analysisDetails: IVolumetricAnalysisUpdateRequest
) => {
  return axios.patch<{id: string}>(`${VOLUMETRIC_ANALYSIS_URL}/${surveyId}`, analysisDetails)
}

export const getAnalystRejectedSurveys = () => {
  return axios.get<ISurveyFromStockyard[]>(ANALYST_REJECTED_SURVEY_URL)
}

export const getAnalystSurveyCount = () => {
  return axios.get<IPendingCompletedRejectedCount>(ANALYST_SURVEY_COUNT_URL)
}
